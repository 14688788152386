import { css } from '@emotion/react';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import CONSTANTS from 'styles/constants';

export const container = () => css`
  max-width: ${CONSTANTS.maxWidth};
  margin: auto;
  padding: 0 ${SPACER.medium};
  ${MEDIA_QUERIES.lgUp} {
    padding: 0 4.5rem;
  }
`;
