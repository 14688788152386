import React from 'react';

import Style from './style';

const Office = ({
  company,
  cityHeadline,
  cityState,
  googleMapsLink,
  street,
}: {
  company: string;
  cityHeadline: string;
  cityState: string;
  googleMapsLink: string;
  street: string;
}) => {
  return (
    <div css={Style.OuterContainer}>
      <h3 css={Style.cityTitle}>{cityHeadline}</h3>
      <div css={Style.companyAndAddressContainer}>
        <strong>{company}</strong>
        <br />
        <a
          css={Style.addressLink}
          href={googleMapsLink}
          target="_blank"
          rel="noreferrer"
        >
          {street}
          <br />
          {cityState}
        </a>
        <br />
      </div>
    </div>
  );
};

export default Office;
