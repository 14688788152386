import React from 'react';

import COMPANY_INFO from 'consts/companyInfo';
import EXTERNAL_URLS from 'consts/externalUrls';

import heroMobile2x from './images/heroMobile@2x.jpg';
import {
  BackgroundImage,
  ContentContainer,
  headerText,
  ImageContainer,
  OuterContainer,
  subHeaderText,
  supportLink,
} from './style';

const Hero = () => {
  return (
    <BackgroundImage>
      <OuterContainer>
        <ContentContainer>
          <h1 css={headerText}>Contact Us</h1>
          <h3 css={subHeaderText}>
            We’re here for you.
            <br />
            Say hi.
          </h3>
          <p>
            Email us at{' '}
            <a
              css={supportLink}
              href={EXTERNAL_URLS.contactForm}
              target="_blank"
              rel="noreferrer"
            >
              {COMPANY_INFO.emails.hello}
            </a>
          </p>
          <p>
            Text us at{' '}
            <a css={supportLink} href={`sms:${COMPANY_INFO.sms}`}>
              {COMPANY_INFO.sms}
            </a>
          </p>
        </ContentContainer>
        <ImageContainer>
          <img
            src={heroMobile2x}
            alt="An open Cabinet with Agency bottles inside"
          />
        </ImageContainer>
      </OuterContainer>
    </BackgroundImage>
  );
};

export default Hero;
