import { css } from '@emotion/react';
import { MEDIA_QUERIES, SPACER, ThemeType } from 'radiance-ui/lib/constants';

const OuterContainer = (theme: ThemeType) => css`
  padding: ${SPACER.x2large} 0;
  border-bottom: solid 1px ${theme.COLORS.border};
  font-size: 0.875rem; //14px;
  line-height: 1.5;

  &:last-of-type {
    border-bottom: none;
  }

  &:first-of-type {
    ${MEDIA_QUERIES.lgUp} {
      padding: ${SPACER.small} 0 ${SPACER.x4large};
    }
  }

  ${MEDIA_QUERIES.lgUp} {
    padding: ${SPACER.x4large} 0;
    display: flex;
  }
`;

const cityTitle = () => css`
  font-size: 31px;
  margin: 0 0 ${SPACER.large};
  ${MEDIA_QUERIES.lgUp} {
    font-size: 36px;
    display: flex;
    margin-right: auto;
  }
`;

const companyAndAddressContainer = () => css`
  ${MEDIA_QUERIES.lgUp} {
    width: 272px;
    padding-top: ${SPACER.small};
  }
`;

const addressLink = () => css`
  text-decoration: none;
`;

export default {
  addressLink,
  companyAndAddressContainer,
  cityTitle,
  OuterContainer,
};
