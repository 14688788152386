import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { MEDIA_QUERIES, SPACER, ThemeType } from 'radiance-ui/lib/constants';

import { container } from 'styles/container';
import { withHoverAnimationStartEmpty } from 'styles/link';

import heroDesktop2x from './images/heroDesktop@2x.jpg';

export const OuterContainer = styled.div`
  ${container};
  padding: 0 ${SPACER.medium} ${SPACER.x5large} ${SPACER.medium};
  display: flex;
  flex-direction: column;
  ${MEDIA_QUERIES.lgUp} {
    height: 100%;
    padding: 0 ${SPACER.x5large} 0 ${SPACER.x5large};
    flex-direction: row;
  }
`;

export const BackgroundImage = styled.div`
  ${MEDIA_QUERIES.lgUp} {
    background-image: url(${heroDesktop2x});
    background-repeat: no-repeat;
    background-position: 46vw;
    background-size: 54vw;
    height: 49.9vw;
    margin-bottom: 6.5rem;
  }
`;

export const ContentContainer = styled.div`
  padding: ${SPACER.x4large} 0 ${SPACER.xlarge};
  ${MEDIA_QUERIES.lgUp} {
    padding: 0;
    width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  margin: auto;
  ${MEDIA_QUERIES.lgUp} {
    display: none;
  }
`;

export const headerText = (theme: ThemeType) => css`
  margin: 0 0 127px;
  color: ${theme.COLORS.black};
  font-size: 42px;
  ${MEDIA_QUERIES.lgUp} {
    margin: 0 0 174px;
    font-size: 68px;
  }
`;

export const subHeaderText = () => css`
  margin: 0 0 ${SPACER.xlarge};
  font-size: 31px;
  ${MEDIA_QUERIES.lgUp} {
    font-size: 36px;
  }
`;

export const supportLink = (theme: ThemeType) => css`
  border-bottom: 0;
  display: inline-block;
  margin-top: ${SPACER.x2small};
  cursor: pointer;
  ${MEDIA_QUERIES.lgUp} {
    ${withHoverAnimationStartEmpty(theme)}
  }
`;
