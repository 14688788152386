import React from 'react';

import COMPANY_INFO from 'consts/companyInfo';

import Office from './office';
import { officeTitle, OfficesContainer, OuterContainer } from './style';

const Offices = () => {
  const officesArray = [
    COMPANY_INFO.sanDiego,
    COMPANY_INFO.sanFrancisco,
    COMPANY_INFO.stLouis,
    COMPANY_INFO.pittsburgh,
  ];

  return (
    <OuterContainer>
      <p css={officeTitle}>Our offices</p>
      <OfficesContainer>
        {officesArray.map((office) => (
          <Office
            key={office.cityHeadline}
            cityHeadline={office.cityHeadline}
            company={office.company}
            street={office.addressLine1}
            cityState={office.addressLine2}
            googleMapsLink={office.googleMapsLink}
          />
        ))}
      </OfficesContainer>
    </OuterContainer>
  );
};

export default Offices;
