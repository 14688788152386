import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { MEDIA_QUERIES, SPACER, ThemeType } from 'radiance-ui/lib/constants';

import { container } from 'styles/container';

export const OuterContainer = styled.div`
  ${container}
  padding: 0 ${SPACER.medium} ${SPACER.large};

  ${MEDIA_QUERIES.lgUp} {
    padding: 0 ${SPACER.x5large} ${SPACER.x3large};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const OfficesContainer = styled.div`
  ${MEDIA_QUERIES.lgUp} {
    width: calc(54vw - 4rem);
  }
`;

export const officeContainer = styled.div`
  ${MEDIA_QUERIES.lgUp} {
    display: flex;
  }
`;

export const officeTitle = (theme: ThemeType) => css`
  width: 107px;
  border-top: solid 1px ${theme.COLORS.primary};
  padding: ${SPACER.large} 0 ${SPACER.medium};
  font-size: 0.875rem;
  ${MEDIA_QUERIES.lgUp} {
    font-size: 1rem;
    width: 176px;
  }
`;
