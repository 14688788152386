import React from 'react';

import Hero from 'components/pages/contactUs/hero';
import Offices from 'components/pages/contactUs/offices';
import Layout from 'components/global/layout';

const ContactUsPage = () => {
  return (
    <Layout
      seo={{
        title: 'Contact Agency | Custom Future-Defining Skincare',
        description:
          'Have questions about custom skincare for aging skin? Talk with a member of our customer success team today. We’re here to listen and answer any questions.',
      }}
    >
      <Hero />
      <Offices />
    </Layout>
  );
};

export default ContactUsPage;
